import {mapActions, mapGetters, mapMutations} from "vuex";

//sections

export default {
    name: "delivery-payment",
    components: {},
    data() {
        return {
            selectedPayment: '',
            payments: [
                {
                    name: this.$t('personalOffice.payWithCard')
                },
                {
                    name: this.$t('basket.cashlessPayments')
                },
            ]
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            deliveriesType: 'card/deliveriesType'
        })
    },

    methods: {

        ...mapMutations({
            ...mapActions({})
        })
    }
}
