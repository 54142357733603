import {mapActions, mapGetters, mapMutations} from "vuex";


//sections
import description from '@/modules/product-card/pages/all-about/section/attributes/index.vue'
import production from '@/modules/product-card/pages/all-about/section/production/index.vue'
import deliveryPayment from "@/modules/product-card/pages/all-about/section/attributes/delivery-payment/index.vue";

export default {
    name: "all-about",
    components: {
        description,
        production,
        deliveryPayment
    },
    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: '/catalog', title: this.$t('catalog.title'), slug: 'catalog'}
            ],
        }
    },
    created() {

    },
    mounted() {
        if (this.currentProduct) {
            document.title = `${this.currentProduct.title + ' - ' + this.$t('productCard.aboutProduct.title')} `
        }
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
        })
    },

    methods: {
        ...mapMutations({}),
        ...mapActions({})
    }
}
