import {mapGetters, mapMutations} from "vuex";

//sections

export default {
    name: "equipment",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },

    },
    data() {
        return {
            amount: 1,
            index: null
        }
    },
    created() {
    },
    computed: {},

    methods: {
        deleteEvent() {
            this.$emit('delete-event')
        },
        getIndex(index) {
            this.index = index
            this.$emit('get-index', this.index)
        },
        incrementEvent() {
            this.amount++
            this.$emit('increment-event', this.amount)
        },
        decrementEvent() {
            if (this.amount > 1) {
                this.amount--
            }
            this.$emit('decrement-event', this.amount)
        }
    }
}
